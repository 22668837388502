import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ListsModal from 'components/ListsModal';
import Spinner from '@johnlewispartnership/wtr-ingredients/ingredients/Spinner';

import styles from 'components/ProductList/ProductList.scss';

const ProductList = ({
  children,
  contentComponent,
  displayInGroups,
  withContainer,
  id,
  InfiniteScroll,
  loading,
  LoadMoreButton,
  withListModal,
}) => {
  if (!loading && (!children || children.length < 1)) return null;

  const list = (
    <div className={classNames(styles.flexGrid, { [styles.inGroups]: displayInGroups })}>
      {children}
    </div>
  );
  const listWithLoadMore = InfiniteScroll ? (
    <InfiniteScroll>{list}</InfiniteScroll>
  ) : (
    <Fragment>
      {list}
      {LoadMoreButton && <LoadMoreButton />}
    </Fragment>
  );

  return (
    <>
      <div className={classNames('container-fluid', { [styles.spinnerWrapper]: loading })}>
        <Spinner ariaTextInactive="Loading finished" isActive={loading} />
      </div>
      {!loading && (
        <div
          className={classNames(styles.productList, {
            [styles.contentComponent]: contentComponent,
          })}
          data-testid="product-list"
          id={id}
        >
          <div
            className={classNames({
              'container-fluid': withContainer,
            })}
          >
            {listWithLoadMore}
            {withListModal && <ListsModal />}
          </div>
        </div>
      )}
    </>
  );
};

ProductList.propTypes = {
  children: PropTypes.element,
  contentComponent: PropTypes.bool,
  displayInGroups: PropTypes.bool,
  withContainer: PropTypes.bool,
  id: PropTypes.string,
  InfiniteScroll: PropTypes.elementType,
  loading: PropTypes.bool,
  LoadMoreButton: PropTypes.elementType,
  withListModal: PropTypes.bool,
};

ProductList.defaultProps = {
  children: null,
  contentComponent: false,
  displayInGroups: false,
  withContainer: true,
  id: 'tSr',
  InfiniteScroll: null,
  loading: false,
  LoadMoreButton: null,
  withListModal: true,
};

ProductList.displayName = 'ProductList';

export default memo(ProductList);
